export const ListOfRoutes = {
  homepage: "/",
  loginpage: "/login",
  management: "/management",
} as const;

export class SisfoopsRoutes {
  static get home(): string {
    return `/` as const;
  }

  static get dashboard(): string {
    return `${this.home}dashboard` as const;
  }

  static get login(): string {
    return `${this.home}login` as const;
  }

  static get notification(): string {
    return `${this.home}notification` as const;
  }
  static get alert(): string {
    return `${this.home}alert` as const;
  }

  static get logout(): string {
    return `${this.home}logout` as const;
  }

  static get account(): string {
    return `${this.home}account` as const;
  }

  static get account_change_password(): string {
    return `${this.account}/change-password` as const;
  }

  static get account_generate_otp(): string {
    return `${this.account}/otp` as const;
  }

  static get report(): string {
    return `${this.home}laporan` as const;
  }

  static get management_report(): string {
    return `${this.report}/laporan_manajemen` as const;
  }

  static get operation_report(): string {
    return `${this.report}/laporan_operasi` as const;
  }

  static get backup_restore(): string {
    return `${this.home}backup-restore` as const;
  }

  static get backup(): string {
    return `${this.backup_restore}/backup` as const;
  }

  static get restore(): string {
    return `${this.backup_restore}/restore` as const;
  }

  static get manajemen(): string {
    return `${this.home}manajemen` as const;
  }
  static get operation(): string {
    return `${this.manajemen}/operasi` as const;
  }
  static get operationPlanning(): string {
    return `${this.operation}/perencanaan` as const;
  }
  static get operationPreparation(): string {
    return `${this.operation}/kesiapan` as const;
  }
  static get operationApproval(): string {
    return `${this.operation}/persetujuan` as const;
  }
  static get operationEvaluation(): string {
    return `${this.operation}/evaluasi` as const;
  }
  static get penugasan(): string {
    return `${this.manajemen}/penugasan` as const;
  }
  static get delegationTask(): string {
    return `${this.penugasan}/penunjukan` as const;
  }
  static get implementationTask(): string {
    return `${this.penugasan}/pelaksanaan` as const;
  }
  static get masterData(): string {
    return `${this.home}masterdata` as const;
  }
  static get masterDataOperasi(): string {
    return `${this.masterData}/operasi` as const;
  }
  static get masterDataPenugasan(): string {
    return `${this.masterData}/penugasan` as const;
  }
  static get management(): string {
    return `${this.home}management` as const;
  }
  static get management_superadmin(): string {
    return `${this.management}/superadmin` as const;
  }
  static get management_account(): string {
    return `${this.management_superadmin}/account` as const;
  }
  static get management_workflow(): string {
    return `${this.management_superadmin}/workflow` as const;
  }
}
