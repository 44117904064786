import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { APersonel } from "../../api/personelAPI";
import {
  convertToEncodedURL,
  jsonToFormData,
} from "../../assets/usefulFunctions";
import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "../../types";
import {
  AGetPersonelReqObj,
  APersonelCreateObj as APersonelNewObj,
  APersonelObj,
} from "../../types/endpoints/personel/personel";
export const personelStoreAPI = createApi({
  reducerPath: "personelStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PersonelList", "ApprovalList", "PersonelEligibleList"],
  endpoints: (builder) => {
    return {
      getPersonel: builder.query<APersonel[], APersonelObj>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/personel/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<APersonel[]>) =>
          response.data,
        providesTags: (_, __, { id }) => [
          "PersonelList",
          { type: "PersonelList", id },
        ],
      }),
      getPersonelPagination: builder.query<
        APaginationEntity<APersonel[]>,
        BackendPaginationRequestObject<Partial<AGetPersonelReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/personel" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<APersonel[]>) =>
          response.data,
        providesTags: (_, __, { id }) => [
          "PersonelList",
          { type: "PersonelList", id },
        ],
      }),
      createPersonel: builder.mutation<
        BackendDataShape<object>,
        APersonelNewObj
      >({
        query: (obj) => {
          const data = jsonToFormData(obj);
          return {
            url: "/personel/create",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["PersonelList"],
      }),
    };
  },
});

export const {
  useGetPersonelQuery,
  useGetPersonelPaginationQuery,
  util: { resetApiState: resetPersonelStoreAPI },
} = personelStoreAPI;
