import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { AccountObj, AnAccount } from "api/account";
import { BackendDataShape, PersAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { LoginObj } from "api/credentialsAPI";

import { convertToEncodedURL } from "assets/usefulFunctions";

export const userStoreAPI = createApi({
  reducerPath: "userStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${PersAPI}/user`,
    prepareHeaders: (headers, { endpoint }) => {
      const excludedEndpoints = ["changePasswordWithToken"];
      /**
       * @description selain endpoint yang ada di value excludedEndpoints, tidak
       * akan melakukan set headers.
       */
      if (!excludedEndpoints.includes(endpoint)) {
        let token = CookieMonster.loadCookie("jwt");
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  tagTypes: ["UserList", "User"],
  endpoints: (builder) => {
    return {
      letUserLogin: builder.mutation({
        query: (obj: LoginObj) => {
          return {
            url: "/login",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["UserList"],
      }),
      getUser: builder.query<AnAccount[], Partial<AccountObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AnAccount[]>) =>
          response.data,
        providesTags: ["UserList"],
      }),

      createUser: builder.mutation<
        BackendDataShape<AnAccount>,
        Partial<AccountObj>
      >({
        query: (obj) => {
          return {
            url: "/register",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["UserList"],
      }),

      updateUser: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<AccountObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["UserList"],
      }),

      deleteUser: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["UserList"],
      }),
      uploadProfileUserImage: builder.mutation<
        BackendDataShape<string>,
        FormData
      >({
        query: (obj) => {
          return {
            url: `/picture/upload`,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["User"],
      }),
      changePassword: builder.mutation<
        BackendDataShape<string>,
        {
          id: string;
          newPassword: string;
        }
      >({
        query: (obj) => {
          return {
            url: `/change-password/${obj.id}`,
            method: "PATCH",
            body: {
              newPassword: obj.newPassword,
            },
          };
        },
        invalidatesTags: ["User"],
      }),
      changePasswordWithToken: builder.mutation<
        BackendDataShape<string>,
        {
          token: string;
          newPassword: string;
        }
      >({
        query: ({ token, newPassword }) => {
          return {
            url: `/change-password`,
            method: "PATCH",
            body: {
              password: newPassword,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        invalidatesTags: ["User"],
      }),
      generateOtp: builder.mutation<
        BackendDataShape<string>,
        { password: string }
      >({
        query: (obj) => {
          return {
            url: `/otp`,
            method: "PATCH",
            body: obj,
          };
        },
      }),
      validateOtp: builder.mutation<
        BackendDataShape<{ token: string }>,
        { otp: string }
      >({
        query: (obj) => {
          return {
            url: `/otp/validate`,
            method: "PATCH",
            body: obj,
          };
        },
      }),
    };
  },
});

export const {
  useGetUserQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUploadProfileUserImageMutation,
  useChangePasswordMutation,
  useChangePasswordWithTokenMutation,
  useGenerateOtpMutation,
  useValidateOtpMutation,
  util: { resetApiState: resetUserStoreAPI },
} = userStoreAPI;
