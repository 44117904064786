import React from "react";
import { AxiosResponse } from "axios";
import { Dispatch, FormEvent, SetStateAction } from "react";
import autoTable from "jspdf-autotable";
import jsPDF, { jsPDFOrientations, jsPDFUnits } from "jspdf";
import * as XLSX from "xlsx";

import { BackendDataShape } from "api/config";
import { PermissionDataType, UserDataWOToken } from "api/credentialsAPI";
import { APersonel } from "api/personelAPI";

import { NRPOptsType } from "hooks/usePersonelOpts";
import createCSVFile, { CSVProps } from "./createCSVFile";
import { InputVariant } from "components/InputForm/InputText";
import { COLORS } from "../theme";

export type ValueInterval = `${number}${"m" | "h" | "d" | "w" | "mo" | "y"}`;

export function convertDashTextToProper(str: string): string {
  let strArr = str.split("_");
  return strArr
    .map((val) => {
      let [a, ...rest] = val.split("");
      return `${a.toUpperCase()}${rest.map((a) => a).join("")}`;
    })
    .join(" ");
}

export const checkIfKeyExistInObject = <T extends object>(
  key: keyof T,
  object: T,
): boolean => {
  if (!object) return false;
  const keySelected = Object.keys(object).some((item) => item === key);
  return !!keySelected;
};

export const checkIsDateValid = (date: Date | string): boolean => {
  return !Number.isNaN(new Date(date).getTime());
};

export const extractColorsFromLinearGradient = (
  linearGradient: string,
): [string, string] => {
  const matches = linearGradient.match(/#([a-f0-9]{6}|[a-f0-9]{3})/gi);
  if (matches && matches.length >= 2) {
    return [matches[0], matches[matches.length - 1]];
  }
  throw new Error("Invalid linear-gradient value");
};

export const backgroundVariantColorSwitch = (
  variant?: InputVariant,
  disabled?: boolean,
): string => {
  if (variant) {
    switch (variant) {
      case "danger":
        return addAlphatoHexColor(COLORS.red_1_puspenerbal, 0.3);
      case "warning":
        return addAlphatoHexColor(COLORS.yellow_puspenerbal_1, 0.3);
      case "success":
        return addAlphatoHexColor(COLORS.green_1_puspenerbal, 0.3);
      case "dark":
        return COLORS.black_1_puspenerbal;
      case "light":
      default:
        if (disabled) {
          return COLORS.light_brown_1_puspenerbal;
        } else {
          return COLORS.black_1_puspenerbal;
        }
    }
  } else {
    if (disabled) {
      return COLORS.light_brown_1_puspenerbal;
    } else {
      return COLORS.black_1_puspenerbal;
    }
  }
};

export const transformToNRPOptions = (list: APersonel[]): NRPOptsType[] =>
  list.map((val) => {
    const textLabelNrp = concatPerwiraLabelInNrp(
      val.nrp.toString(),
      val.rankCategoryGeneral,
    );
    if (textLabelNrp) {
      return {
        label: `${textLabelNrp} - ${val.name}`,
        value: val.id,
        ...val,
      };
    } else {
      return {
        label: `${val.nrp} - ${val.name}`,
        value: val.id,
        ...val,
      };
    }
  });

export const filterObjectUndefinedValue = <T extends object>(data: T): T => {
  const filteredEntries = Object.entries(data).filter(
    // @typescript-eslint/no-unused-vars
    ([_, value]) => value !== undefined,
  );
  const filteredObject = Object.assign(
    {} as T,
    ...filteredEntries.map(([k, v]) => ({ [k]: v })),
  );
  return filteredObject;
};

/**
 * @description function that can filters out NaN and undefined values
 * from an object in TypeScript with generic type checking
 * @param obj
 * @returns
 */
export const filterNaNAndUndefined = <T extends { [key: string]: any }>(
  obj: T,
): T => {
  const filteredObj: { [key: string]: any } = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // Check for NaN and undefined
      if (value !== undefined && !Number.isNaN(value)) {
        filteredObj[key] = value;
      }
    }
  }
  //casting filtered object
  return filteredObj as T;
};

/**
 * @description function for download file.csv with Bearer token
 * @param response
 * @param name_csv
 * @returns file.csv
 */
export const downloadCSVWithBearerToken = (
  response: AxiosResponse<any, any>,
  name_csv?: string,
) => {
  // Check if the response content-type is 'text/csv'
  const contentType = response.headers["content-type"];
  if (contentType && contentType.toLowerCase().includes("text/csv")) {
    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: "text/csv" });

    // Create a link element
    const link = document.createElement("a");

    if (!!name_csv) {
      link.download = name_csv;
    }

    // Create a URL for the Blob and set it as the link's href
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  } else {
    // Handle the case when the response is not a CSV
    console.error("Invalid content type. Expected text/csv.");
  }
};

export const isUserAllowedFormsByPermission = (
  user: UserDataWOToken | undefined,
  permission: PermissionDataType,
) => {
  if (user) {
    if (user.usertype.role === "user") {
      return true;
    } else {
      const allowedForms = user.usertype.permissionData?.find((item) => {
        return item === permission;
      });
      return !!allowedForms;
    }
  }
  return false;
};

export const replaceInputTextToUppercase = (e: FormEvent<HTMLInputElement>) => {
  e.currentTarget.value = "" + e.currentTarget.value.toUpperCase();
  return e.currentTarget.value;
};
export const replaceInputTextToNumberOnly = (
  e: FormEvent<HTMLInputElement>,
) => {
  e.currentTarget.value = "" + e.currentTarget.value.replace(/\D/g, "");
  return e.currentTarget.value;
};

export const numberWithDots = (num: number) =>
  num
    .toFixed()
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
export const numberWithCommas = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export type ChartAcceptableTimeUnit =
  | "millisecond"
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";

export const convertResponseImageUrlState = (
  setState: Dispatch<SetStateAction<string>>,
  res: AxiosResponse<any, any>,
) => {
  let image = btoa(
    new Uint8Array(res.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      "",
    ),
  );
  setState(`data:${res.headers["content-type"].toLowerCase()};base64,${image}`);
};

export const capitalizeWords = (arrString: string[]) => {
  return arrString.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

export const convertAxiosResponseToString = (
  res: AxiosResponse<any, any>,
): string => {
  let image = btoa(
    new Uint8Array(res.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      "",
    ),
  );
  return `data:${res.headers["content-type"].toLowerCase()};base64,${image}`;
};

export const newArrayWithUniqueValueSameKey = <T, K extends keyof T>(
  key: K,
  array: T[],
) => {
  const arrayUniqueByKey = [
    ...new Map(array.map((item) => [item[key], item])).values(),
  ];
  return arrayUniqueByKey;
};

export function extractTimeFormat(
  interval: ValueInterval,
): ChartAcceptableTimeUnit {
  let text = interval.match(/([a-z]+)/);
  if (text) {
    let time = text[0];
    switch (time) {
      case "m":
        return "minute";
      case "h":
        return "hour";
      case "d":
        return "day";
      case "w":
        return "week";
      case "mo":
        return "month";
      case "y":
        return "year";
      default:
        throw new Error("invalid interval passed!");
    }
  } else {
    throw new Error("invalid interval passed!");
  }
}

export const idrCurrencyFormatter = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
});

export const concatPerwiraLabelInNrp = (
  nrp: string,
  rankCategoryGeneral: string,
): string => {
  if (!rankCategoryGeneral) return "";
  if (rankCategoryGeneral.toUpperCase() === "PERWIRA") {
    return `${nrp}/P`;
  } else {
    return nrp;
  }
};

export const idNumberFormatter = new Intl.NumberFormat("id-ID", {
  // style
});

/**
 *
 * @param number the number to round
 * @param n decimal point to round
 */
export function roundNumber(number: number, n: number) {
  if (n < 0) return number;
  let rounding10 = Math.pow(10, n);
  return Math.round(number * rounding10) / rounding10;
}

const basicColors: string[] = [
  "#11B911",
  "#C71585",
  "#00A6DD",
  "#7FB800",
  "#0D2C54",
  "#E10000",
  "#864BFF",
  "#17C37B",
];

export function palleteGenerator(numberOfColors: number = 5): string[] {
  if (numberOfColors <= 0 || typeof numberOfColors !== "number") {
    return [];
  }

  if (numberOfColors < basicColors.length) {
    return basicColors.slice(0, numberOfColors);
  } else {
    let remainingColors = palleteGenerator(numberOfColors - basicColors.length);
    if (remainingColors.length) {
      return basicColors.slice().concat(remainingColors);
    } else return basicColors.slice();
  }
}

export function convertToEncodedURL(obj: { [key: string]: any }) {
  let formBody = [];
  for (const property in obj) {
    if (typeof obj[property] === "undefined") continue;
    const key = encodeURIComponent(property);
    const value = encodeURIComponent(obj[property]);
    formBody.push(`${key}=${value}`);
  }
  return formBody.join("&");
}

export function pickRandomFromArray<T extends any>(arr: Array<T>) {
  let i = Math.floor(Math.random() * arr.length);
  return arr[i];
}

export const BackendDateFormat = "yyyy-MM-dd HH:mm:ss";

export const constructProperName = (
  firstname?: string,
  lastname?: string,
): string => {
  let name = `${firstname ?? ""} ${lastname ?? ""} `.trim();
  if (name.length) return name;
  return "-";
};

export const onlyNumber = (str: string) => {
  const onlyNumbers = /^[0-9]+$/;
  const testNumber = onlyNumbers.test(str);
  if (testNumber) return true;
  return false;
};

export const addAlphatoHexColor = (color: string, opacity: number): string => {
  const newOpacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + newOpacity.toString(16).toUpperCase();
};

export const checkIfUndefined = (item: any): item is undefined => {
  return typeof item === "undefined";
};

export const buildFormData = (
  formData: FormData,
  data: any,
  parentKey?: string,
) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}` : key);
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey ?? "", value);
  }
};

export const jsonToFormData = (data: any): FormData => {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
};

export const downloadxls = (
  e: React.FormEvent,
  data: any,
  title: string,
  heading: string[][],
) => {
  e.preventDefault();
  // const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();

  //Had to create a new workbook and then add the header
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.sheet_add_aoa(ws, heading);

  //Starting in the second row to avoid overriding and skipping headers
  XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });

  XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, title);
};

export const exportPDF = (
  _: React.FormEvent,
  data: string[][] | undefined,
  fileName: string,
  headers: string[][],
  portraitView: jsPDFOrientations,
  title: string,
) => {
  const unit: jsPDFUnits = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const doc = new jsPDF(portraitView, unit, size);
  doc.text(title, 40, 50);
  autoTable(doc, {
    head: headers,
    body: data,
    margin: { top: 50 },
  });

  doc.save(fileName);
};

export const responseApprovalToastMessageFromBackend = (
  response: BackendDataShape<object>,
): string | undefined => {
  if (!response) return "";
  const { data } = response;
  if (typeof data === "object" && "approvalId" in data) {
    return "Menunggu Persetujuan";
  } else {
    return "";
  }
};

export const downloadCSV = <D extends Object>({
  headers,
  columns,
  title,
}: CSVProps<D> & { title: string }) => {
  const csvText = createCSVFile({ headers, columns });
  const link = document.createElement("a");
  link.setAttribute("href", csvText);
  link.setAttribute("download", `${title}.csv`);
  link.style.visibility = "hidden";
  link.click();
  link.remove();
};

export const getFullDateWithTime = (date: string) => {
  return new Date(date).toLocaleString("id-ID");
};

export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0;
};

export const filterObjectIfValueIsEmpty = <T extends Record<string, any>>(
  obj: T,
): Partial<T & object> => {
  const result: Partial<T[Extract<keyof T, string>] & object> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (
        // value !== 0 &&
        value !== undefined &&
        value !== null &&
        value !== "{}" &&
        value !== "" &&
        !((value as any) instanceof File)
      ) {
        if (typeof value === "object" && !Array.isArray(value)) {
          const filteredValue = filterObjectIfValueIsEmpty(value);
          if (!isEmptyObject(filteredValue)) {
            result[key] = filteredValue;
          }
        } else {
          result[key] = value;
        }
      }
    }
  }
  return result;
};
