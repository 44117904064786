import React from "react";
import { Navigate } from "react-router-dom";

import { KnownUsertypeName } from "api/credentialsAPI/usertype";

import { checkIfUndefined } from "assets/usefulFunctions";

import { SisfoopsRoutes } from "config/nav";

import { useUsertypeName } from "hooks/auth/useUsertype";

interface Props {}

const Redirector: React.FunctionComponent<Props> = () => {
  const usertypeName = useUsertypeName();
  const to = getDefaultRoute(usertypeName);
  // const sisfo = useGetSisfoParentUrl();

  if (checkIfUndefined(usertypeName)) {
    return <>Loading...</>;
  }
  return <Navigate replace to={`${to}`} />;
};

export default Redirector;

export const getDefaultRoute = (usertypeLogin?: KnownUsertypeName) => {
  switch (usertypeLogin) {
    case "personel":
      return SisfoopsRoutes.account_generate_otp;
    case "superadmin":
      return SisfoopsRoutes.management_workflow;
    default:
      return SisfoopsRoutes.dashboard;
    /**
     * @todo butuh penyesuaian default route untuk semua user
     */
  }
};

export const useDefaultRoute = () => {
  const usertypeName = useUsertypeName();
  return React.useMemo(() => {
    return getDefaultRoute(usertypeName);
  }, [usertypeName]);
};
