import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, PersAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { AUsertype } from "api/credentialsAPI/usertype";

import { convertToEncodedURL } from "assets/usefulFunctions";

export const usertypeStoreAPI = createApi({
  reducerPath: "usertypeStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${PersAPI}/usertype`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["usertypelist"],
  endpoints: (builder) => {
    return {
      /**
       * query<ShapeOfReturn, ShapeOfArgs>
       */
      getUsertype: builder.query<AUsertype[], { id?: number }>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          // console.log({ updateUrl: url });
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AUsertype[]>) =>
          response.data,
        providesTags: ["usertypelist"],
      }),
    };
  },
});

export const {
  useGetUsertypeQuery,
  util: { resetApiState: resetUsertypeStoreAPI },
} = usertypeStoreAPI;
