import {
  Action,
  configureStore,
  isRejectedWithValue,
  Middleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import { UnknownAsyncThunkRejectedWithValueAction } from "@reduxjs/toolkit/dist/matchers";
import { toast } from "react-toastify";
import { ErrorMessageBackendDataShape } from "../types";
import { assignmentStoreAPI } from "./assignment";
import { masterAssignmentStoreAPI } from "./masterAssignment";
import { masterOperationStoreAPI } from "./masterOperation";
import { notificationStoreAPI } from "./notificationStore/notificationStoreAPI";
import { lovOperationStoreAPI } from "./operation/lovOperationStoreAPI";
import { operationStoreAPI } from "./operation/operationStoreAPI";
import { personelStoreAPI } from "./personelStore/personelStoreAPI";
import { reportStoreAPI } from "./reportStore/reportStoreAPI";
import sidebarStore from "./sidebarStore";
import userStore from "./userStore";
import { userStoreAPI } from "./userStore/userStoreAPI";
import { usertypeStoreAPI } from "./usertypeStore/usertypeStoreAPI";

export const store = configureStore({
  /**
   * @todo sort these reducer slices alphabetically, ascending.
   */
  reducer: {
    userStore,
    sidebarStore,
    [userStoreAPI.reducerPath]: userStoreAPI.reducer,
    [notificationStoreAPI.reducerPath]: notificationStoreAPI.reducer,
    [personelStoreAPI.reducerPath]: personelStoreAPI.reducer,
    [usertypeStoreAPI.reducerPath]: usertypeStoreAPI.reducer,
    [masterOperationStoreAPI.reducerPath]: masterOperationStoreAPI.reducer,
    [masterAssignmentStoreAPI.reducerPath]: masterAssignmentStoreAPI.reducer,
    [operationStoreAPI.reducerPath]: operationStoreAPI.reducer,
    [reportStoreAPI.reducerPath]: reportStoreAPI.reducer,
    [lovOperationStoreAPI.reducerPath]: lovOperationStoreAPI.reducer,
    [assignmentStoreAPI.reducerPath]: assignmentStoreAPI.reducer,
  },
  middleware: (getMiddleWares) => {
    /**
     * @todo remove this logger if no longer needed
     */
    const logMiddleWare: Middleware = () => {
      return (next) => {
        return (action) => {
          if (isRejectedWithValue(action)) {
            const act = action as UnknownAsyncThunkRejectedWithValueAction;
            const payload = act.payload;

            const isBEErrorPayload = (
              payload: any,
            ): payload is ErrorMessageBackendDataShape => {
              return payload?.data?.status && payload.data.status === "error";
            };
            if (isBEErrorPayload(payload)) {
              toast.error(payload.data.message);
              // console.log(payload.data.message)
            }
          }
          return next(action);
        };
      };
    };

    /**
     * @todo sort these middlewares alphabetically, ascending.
     */
    let middlewares = getMiddleWares().concat([
      logMiddleWare,
      userStoreAPI.middleware,
      notificationStoreAPI.middleware,
      personelStoreAPI.middleware,
      usertypeStoreAPI.middleware,
      masterOperationStoreAPI.middleware,
      masterAssignmentStoreAPI.middleware,
      operationStoreAPI.middleware,
      reportStoreAPI.middleware,
      lovOperationStoreAPI.middleware,
      assignmentStoreAPI.middleware,
    ]);
    return middlewares;
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
