import CookieMonster from "api/CookieMonster";
import { CredsLinks, BackendDataShape } from "api/config";
import { UserDataWOToken } from "api/credentialsAPI";
import { convertToEncodedURL } from "assets/usefulFunctions";
import axios from "axios";

/**
 *
 * @param shouldNavigate in real case, we want to navigate the screen i.e. `true` on `logout`. but in testing condition, we do not need this i.e. `false`
 * @returns a logout function
 */

export interface GetUserDataReqObj {
  id?: number;
  user_id?: number;
  username?: string;
  email?: string;
}

export function getUser(obj: GetUserDataReqObj) {
  let params = "";
  if (obj) {
    params = convertToEncodedURL(obj);
    params = params.length ? "?" + params : "";
  }

  const url = `${CredsLinks.user(params)}`;

  return axios.get<BackendDataShape<UserDataWOToken[]>>(url, {
    data: obj,
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  });
}
