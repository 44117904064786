import CookieMonster from "api/CookieMonster";
import { LoginObj, letUserLogin } from "api/credentialsAPI";

export const useLogin = () => {
  return async (obj?: LoginObj) => {
    const {
      data: { data },
    } = await letUserLogin(obj);
    const { jwt, exp, iat, ...rest } = data;

    // Samakan cooke yg diremove dengan cookie yg diSave
    CookieMonster.saveAllTheseCookies(
      {
        jwt,
        id: rest.id,
        username: rest.username,
        usertypeId: rest.usertypeId,
        userDetailId: rest.userDetailId ?? undefined,
        usertypeRole: rest.usertypeRole,
        usertypeName: rest.usertypeName,
      },
      { expires: new Date(exp * 1000) },
    );
    return rest;
  };
};
