export let UsedAPI = "";
export let BasicURL = process.env.REACT_APP_ENDPOINT ?? "";

export let PersAPI = "";
export let PersURL = process.env.REACT_APP_SISFOPERS_ENDPOINT ?? "";

UsedAPI = BasicURL + "/api";
PersAPI = PersURL + "/api";

export const CredsLinks = {
  login: `${PersAPI}/User/login`,
  logout: `${PersAPI}/auth/logout`,
  user: (params: string) => `${PersAPI}/user/find/${params}` as const,
} as const;

export const UsertypeLinks = {
  find: `${PersAPI}/usertype/find`,
} as const;

export const NotificationLinks = {
  list: `${PersAPI}/notification/list`,
} as const;

export const DashboardLinks = {
  usage: `${UsedAPI}/dashboard/usage`,
  sensorlog: `${UsedAPI}/dashboard/sensorlog/find`,
  enviroBoxSensorLog: `${UsedAPI}/dashboard/sensorlog/envirobox`,
} as const;

export interface BackendDataShape<D> {
  status: string;
  data: D;
}
