import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI, BackendDataShape } from "api/config";
import CookieMonster from "api/CookieMonster";
import { convertToEncodedURL } from "assets/usefulFunctions";

export const lovOperationStoreAPI = createApi({
  reducerPath: "lovOperationStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "OperationStatusList",
    "OperationPersonelList",
    "OperationMaterialList",
  ],
  endpoints: (builder) => {
    return {
      getStatusLov: builder.query<string[], object>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = `operation/lov/status` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
        providesTags: ["OperationStatusList"],
      }),
      getPersonelLov: builder.query<string[], object>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = `operation/lov/personel` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
        providesTags: ["OperationPersonelList"],
      }),
      getMaterialLov: builder.query<string[], object>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = `operation/lov/materiel` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
        providesTags: ["OperationMaterialList"],
      }),
    };
  },
});

export const {
  useGetStatusLovQuery,
  useGetPersonelLovQuery,
  useGetMaterialLovQuery,
  util: { resetApiState: resetLovOperationStoreAPI },
} = lovOperationStoreAPI;
