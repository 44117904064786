import React, { ReactNode, Suspense } from "react";
import { createUseStyles } from "react-jss";
// import { Outlet } from "react-router";
import { imageBackgoroudLayoutPage } from "../assets/images";
import { BasicTheme } from "../assets/theme";
import LoaderPage from "../components/atoms/Loader/LoaderPage/LoaderPage";
import Sidebar from "../components/Sidebar/Sidebar";
import { SidebarItemProps } from "../components/Sidebar/SidebarItem";
// import useGetSisfoParentUrl from "../hooks/useGetSisfoParentUrl";

interface Props {
  sidebarItems: SidebarItemProps[];
  children: ReactNode;
}

const useStyles = createUseStyles((_: BasicTheme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "row",
      minHeight: "100vh",
      backgroundColor: "#000912",
      backgroundImage: `url(${imageBackgoroudLayoutPage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    sidebar: {
      flexBasis: "230px",
      maxHeight: "100vh",
      display: "flex",
    },
    outlet: {
      flex: 1,
      // backgroundColor: theme.backgroundColor,
      maxHeight: "100vh",
      overflowY: "auto",
    },
  };
});

const Layout: React.FunctionComponent<Props> = ({ sidebarItems, children }) => {
  const classes = useStyles();
  // const sisfoParentUrl = useGetSisfoParentUrl();
  return (
    <div className={classes.container}>
      <Sidebar sidebarItems={sidebarItems} />
      <div className={classes.outlet}>
        {/**
         * @todo add new loading fallback here
         */}
        <Suspense fallback={<LoaderPage />}>{children}</Suspense>
      </div>
    </div>
  );
};

export default Layout;
