import axios from "..";
// import { MockUserData } from "../../mocks";
import { Nullable } from "../../types";
import { BackendDataShape, CredsLinks } from "../config";
import CookieMonster from "../CookieMonster";
import { KnownUsertype, KnownUsertypeName } from "./usertype";

/**
 * @todo move this to `/types/endpoint/auth`
 */
export interface LoginObj {
  username: string;
  password: string;
  remember?: 1 | 0;
}

/**
 * @deprecated
 */
export interface UserData {
  id: number;
  username: string;
  usertypeId: number;
  usertypeName: KnownUsertype;
  companyId: number;
  locationId: number;
  companyName: string;
  firstname: string;
  lastname: string;
  email: string;
  phonenumber: string;
  lastseennotification: string;
  profileImage?: string;
  /**
   * the token
   */
  jwt: string;
  // /**
  //  * token max age in s
  //  */
  // max_age_s: 86400;

  iat: number;
  /**
   * expire in second
   */
  exp: number;
}

/**
 * @todo move this to `/types/endpoint/auth`
 */
export type PermissionDataType =
  | "profesipenerbang"
  | "profesinonpenerbang"
  | "mindik"
  | "kesehatan"
  | "watpers"
  | "hukum"
  | "dalpers";

/**
 * @todo rename this interface
 * @todo move this to `/types/endpoint/auth`
 */
export interface NewUserType {
  id: number;
  name: KnownUsertypeName;
  parentId: Nullable<number>;
  role: KnownUsertype;
  permissionData: Nullable<PermissionDataType[]>;
}

/**
 * @todo move this to `/types/endpoint/auth`
 */
export interface LoginResult {
  id: number;
  username: string;
  usertypeId: number;
  /**
   * @example "personel"
   */
  usertypeName: KnownUsertypeName;
  /**
   * @example  "user"
   */
  usertypeRole: string;
  /**
   * only applicable for user with userTypeName Personel
   * @example 41
   */
  userDetailId?: Nullable<number>;
  /**
   * @example 1668658928
   */
  iat: number;
  /**
   * @example 1668745328
   */
  exp: number;
  jwt: string;
}

/**
 * @todo move this to `/types/endpoint/auth`
 */
// @ts-ignore
// eslint-disable-next-line
const sampleLoginResult: LoginResult = {
  id: 67,
  username: "kasiKum_test",
  usertypeId: 20,
  usertypeName: "kasiKum",
  usertypeRole: "admin",
  iat: 1668659283,
  exp: 1668745683,
  jwt: "",
};

/**
 * fixed
 * @todo rename this interface
 * @todo move this to `/types/endpoint/auth`
 */
export interface NewUserData {
  /**
   * @example 107
   */
  id: number;
  /**
   * @example "11582/T"
   */
  username: string;
  /**
   * @example 6
   */
  usertypeId: number;
  /**
   * @example "AJI NUGRAHA"
   */
  name: string;
  status: boolean;
  /**
   * @example "2022-10-10T00:00:00.000Z"
   */
  created_at: string;
  /**
   * @example "2022-10-10T00:00:00.000Z"
   */
  updated_at: string;
  usertype: NewUserType;
  /**
   * `null` for non personel user
   */
  userdetail: Nullable<UserDetail>;
}

/**
 * @todo move this to `/types/endpoint/auth`
 */
// @ts-ignore
// eslint-disable-next-line
const SampleUserData: NewUserData = {
  id: 107,
  username: "11582/T",
  usertypeId: 6,
  name: "AJI NUGRAHA",
  status: true,
  created_at: "2022-10-10T00:00:00.000Z",
  updated_at: "2022-10-10T00:00:00.000Z",
  usertype: {
    id: 6,
    name: "personel",
    role: "user",
    parentId: null,
    permissionData: null,
  },
  userdetail: {
    id: 41,
    nrp: "11582/T",
    name: "AJI NUGRAHA",
    sex: "L",
    religion: "ISLAM",
    ethnic: "Jawa",
    address: null,
    tmtFiktif: null,
    placeBirthdate: "Surabaya",
    birthdate: "1960-01-01T00:00:00.000Z",
    status: "aktif",
    userId: 107,
    createdBy: 73,
    createdAt: "2022-10-10T00:00:00.000Z",
    updatedAt: "2022-10-10T00:00:00.000Z",
    updatedBy: 73,
  },
};

export type KnownJenisKelamin = "P" | "L" | "Laki-Laki" | "Perempuan";

/**
 * @todo move this to `/types/endpoint/auth`
 */
export interface UserDetail {
  id: number;
  nrp: string;
  // age: number;
  name: string;
  sex: KnownJenisKelamin;
  religion: string;
  ethnic: string;
  address: Nullable<string>;
  // korps: string;
  // rank: string;
  // tmtTni: Nullable<string | number>;
  tmtFiktif: Nullable<string | number>;
  // rankPeriod: Nullable<string | number>;
  // position: Nullable<string>;
  // positionPeriod: Nullable<string | number>;
  // militaryPeriod: Nullable<string | number>;
  /**
   * @example "1960-01-01T00:00:00.000Z"
   */
  birthdate: string;
  /**
   * @example "aktif"
   */
  status: string;
  placeBirthdate: string;
  // imageprofile: Nullable<string>;
  userId: number;

  createdBy: number;
  /**
   * @example "2022-10-10T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @example "2022-10-10T00:00:00.000Z"
   */
  updatedAt: string;
  updatedBy: number;
}

/**
 * @todo move this to `/types/endpoint/auth`
 */
export type UserDataWOToken = Omit<NewUserData, "jwt" | "iat" | "exp">;

export function letUserLogin(obj?: LoginObj) {
  const url = `${CredsLinks.login}`;
  const payload = obj
    ? obj
    : {
        jwt: CookieMonster.loadCookie("jwt"),
      };
  return axios.post<BackendDataShape<LoginResult>>(url, payload);
}
export function letUserLogout() {
  const url = `${CredsLinks.logout}`;
  return axios.get<BackendDataShape<string>>(url);
}
