import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI, BackendDataShape } from "api/config";
import CookieMonster from "api/CookieMonster";
import { convertToEncodedURL } from "assets/usefulFunctions";
import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import { AnOperationParams } from "types/endpoints/operation";

export const reportStoreAPI = createApi({
  reducerPath: "reportStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/report`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["ReportList", "FilterReportList"],
  endpoints: (builder) => {
    return {
      getReportData: builder.query<
        APaginationEntity<object[]>,
        BackendPaginationRequestObject<Partial<AnOperationParams>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<object[]>) => {
          const { meta, entities } = response.data;
          const dataWithChip = entities.map((val) => {
            return {
              ...val,
            };
          });
          return { meta, entities: dataWithChip };
        },
        providesTags: ["ReportList"],
      }),
      getReportFilterLov: builder.query<string[], object>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = `/type` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
        providesTags: ["FilterReportList"],
      }),
    };
  },
});

export const {
  useGetReportDataQuery,
  useGetReportFilterLovQuery,
  util: { resetApiState: resetReportStoreAPI },
} = reportStoreAPI;
