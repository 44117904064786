import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI, BackendDataShape } from "api/config";
import CookieMonster from "api/CookieMonster";
import { convertToEncodedURL } from "assets/usefulFunctions";
import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import { AssignmentObj, AssignmentParams } from "types/endpoints/assignment";

export const assignmentStoreAPI = createApi({
  reducerPath: "assignmentStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/assignment`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["AssignmentList"],
  endpoints: (builder) => {
    return {
      getAssignment: builder.query<
        APaginationEntity<AssignmentObj[]>,
        BackendPaginationRequestObject<Partial<AssignmentParams>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<AssignmentObj[]>,
        ) => {
          const { meta, entities } = response.data;
          const dataWithChip = entities.map((val) => {
            return {
              ...val,
            };
          });
          return { meta, entities: dataWithChip };
        },
        providesTags: ["AssignmentList"],
      }),
      getAssignmentById: builder.query<AssignmentObj, Partial<AssignmentObj>>({
        query: ({ id, ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = `/${id}` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AssignmentObj>) =>
          response.data,
        providesTags: ["AssignmentList"],
      }),
      createAssignment: builder.mutation<
        BackendDataShape<AssignmentObj>,
        Partial<AssignmentObj>
      >({
        query: (obj) => {
          return {
            url: ``,
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["AssignmentList"],
      }),
      updateAssignment: builder.mutation<
        BackendDataShape<AssignmentObj>,
        { id: number }
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["AssignmentList"],
      }),
      deleteAssignment: builder.mutation<
        BackendDataShape<string>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["AssignmentList"],
      }),
    };
  },
});

export const {
  useCreateAssignmentMutation,
  useGetAssignmentByIdQuery,
  useDeleteAssignmentMutation,
  useGetAssignmentQuery,
  useUpdateAssignmentMutation,
  util: { resetApiState: resetAssignmentStoreAPI },
} = assignmentStoreAPI;
