import { Suspense, lazy } from "react";
import {
  MdDashboard,
  MdOutlineLogout,
  MdViewList,
  MdMonitor,
  MdCollections,
  MdOutlineDescription,
  MdOutlineAccountCircle,
  MdOutlineBackup,
  MdWarning,
} from "react-icons/md";
import { HashRouter, Route, Routes, Outlet } from "react-router-dom";

import { COLORS } from "./assets/theme";

import LoaderPage from "./components/atoms/Loader/LoaderPage/LoaderPage";
import AuthHOC from "./components/auth/AuthHOC";
import { SidebarItemProps } from "./components/Sidebar/SidebarItem";

import { StyledToastContainerPuspenerbal } from "./components/Toast/StyledToastContainerPuspenerbal";

import { SisfoopsRoutes } from "./config/nav";

import Layout from "./layout/Layout";

import NoMatch from "./pages/NoMatch";
import Redirector from "./pages/Redirector";
import UserGate from "components/auth/UserGate";

const LoginPage = lazy(() => import("./pages/LoginPage"));

const SisfoPersLogoutPage = lazy(() => import("./pages/Logout"));

const DashboardPage = lazy(() => import("./pages/Dashboard"));
const OperationPlanningPage = lazy(
  () => import("./pages/Manajemen/Operasi/OperationPlanningPage"),
);
const OperationPreparationPage = lazy(
  () => import("./pages/Manajemen/Operasi/OperationPreparationPage"),
);
const OperationStatusPage = lazy(
  () => import("./pages/Manajemen/Operasi/OperationApprovalPage"),
);
const OperationEvaluationPage = lazy(
  () => import("./pages/Manajemen/Operasi/OperationEvaluatedPage"),
);
const DelegationTaskPage = lazy(
  () => import("./pages/Manajemen/Penugasan/DelegationTaskPage"),
);
const ImplementTaskPage = lazy(
  () => import("./pages/Manajemen/Penugasan/ImplementTaskPage"),
);
const MasterDataOperasiPage = lazy(
  () => import("./pages/MasterData/Operasi/MasterDataOperationPage"),
);
const MasterDataPenugasanPage = lazy(
  () => import("./pages/MasterData/Penugasan/MasterDataAssignmentPage"),
);

const OperationReportPage = lazy(
  () => import("./pages/ReportPage/OperationReportPage"),
);

const ManagementReportPage = lazy(
  () => import("./pages/ReportPage/ManagementReportPage"),
);

const BackupRestorePage = lazy(() => import("pages/BackupRestore"));

const ChangePasswordPage = lazy(
  () => import("pages/AccountPage/ChangePasswordPage"),
);

const GenerateOtpPage = lazy(() => import("pages/AccountPage/GenerateOtpPage"));
const AlertPage = lazy(() => import("pages/AlertPage"));

const WorkflowPage = lazy(() => import("pages/WorkflowPage/index"));
const AccountViewPage = lazy(() => import("pages/AdminPage/index"));

const NotificationPage = lazy(() => import("./pages/NotificationPage"));

const basicSisfopersNav: SidebarItemProps[] = [
  {
    Icon: () => (
      <MdDashboard size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfoopsRoutes.dashboard,
    title: "Dashboard",
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: () => (
      <MdWarning size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfoopsRoutes.alert,
    title: "Peringatan",
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: () => (
      <MdCollections size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfoopsRoutes.operation,
    title: "Master Data",
    kiddos: [
      {
        title: "Operasi",
        shrinkTitle: "OP",
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
        kiddoURL: SisfoopsRoutes.masterDataOperasi,
      },
      {
        title: "Penugasan",
        shrinkTitle: "PN",
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
        kiddoURL: SisfoopsRoutes.masterDataPenugasan,
      },
    ],
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: () => (
      <MdMonitor size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfoopsRoutes.operation,
    title: "Manajemen Data Operasi",
    kiddos: [
      {
        title: "Perencanaan Operasi",
        shrinkTitle: "PO",
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
        kiddoURL: SisfoopsRoutes.operationPlanning,
      },
      {
        title: "Kesiapan Operasi",
        shrinkTitle: "KO",
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
        kiddoURL: SisfoopsRoutes.operationPreparation,
      },
      {
        title: "Persetujuan Operasi",
        shrinkTitle: "PO",
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
        kiddoURL: SisfoopsRoutes.operationApproval,
      },
      {
        title: "Evaluasi Operasi",
        shrinkTitle: "EO",
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
        kiddoURL: SisfoopsRoutes.operationEvaluation,
      },
    ],
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: () => (
      <MdViewList size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfoopsRoutes.manajemen,
    title: "Manajemen Data Penugasan",
    kiddos: [
      {
        title: "Penunjukan Penugasan",
        shrinkTitle: "PN",
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
        kiddoURL: SisfoopsRoutes.delegationTask,
      },
      {
        title: " Pelaksanaan Penugasan",
        shrinkTitle: "LN",
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
        kiddoURL: SisfoopsRoutes.implementationTask,
      },
    ],
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: () => (
      <MdOutlineDescription
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
      />
    ),
    parentURL: SisfoopsRoutes.report,
    title: "Laporan",
    kiddos: [
      {
        title: "Manajemen Laporan ",
        shrinkTitle: "ML",
        kiddoURL: SisfoopsRoutes.management_report,
      },
      {
        title: "Operasi Laporan",
        shrinkTitle: "OL",
        kiddoURL: SisfoopsRoutes.operation_report,
      },
    ],
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: () => (
      <MdViewList size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfoopsRoutes.backup_restore,
    title: "Manajemen Akun",
    kiddos: [
      {
        title: "Akun",
        shrinkTitle: "AK",
        kiddoURL: SisfoopsRoutes.management_account,
      },
      {
        title: "Alur Kerja",
        shrinkTitle: "AL",
        kiddoURL: SisfoopsRoutes.management_workflow,
      },
    ],
    allowedUsers: ["superadmin"],
  },
  {
    Icon: () => (
      <MdOutlineAccountCircle
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
      />
    ),
    parentURL: SisfoopsRoutes.account,
    title: "Akun",
    kiddos: [
      {
        title: "Ubah Password",
        shrinkTitle: "UP",
        kiddoURL: SisfoopsRoutes.account_change_password,
      },
      {
        title: "GENERATE OTP",
        shrinkTitle: "GO",
        kiddoURL: SisfoopsRoutes.account_generate_otp,
      },
    ],
  },
  {
    title: "Pencadangan",
    parentURL: SisfoopsRoutes.backup_restore,
    Icon: () => (
      <MdOutlineBackup size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    kiddos: [
      {
        title: "Backup",
        shrinkTitle: "BK",
        kiddoURL: SisfoopsRoutes.backup,
        allowedUsers: ["superadmin"],
      },
      {
        title: "Restore",
        shrinkTitle: "RS",
        kiddoURL: SisfoopsRoutes.restore,
        allowedUsers: ["superadmin"],
      },
    ],
    allowedUsers: ["superadmin"],
  },
  {
    Icon: () => (
      <MdOutlineLogout size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfoopsRoutes.logout,
    title: "Keluar",
  },
];

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthHOC>
                <Outlet />
              </AuthHOC>
            }
          >
            <Route
              path={SisfoopsRoutes.home}
              element={
                <Layout sidebarItems={basicSisfopersNav}>
                  <AuthHOC>
                    <Outlet />
                  </AuthHOC>
                </Layout>
              }
            >
              <Route index element={<Redirector />} />

              <Route
                path={SisfoopsRoutes.logout}
                element={<SisfoPersLogoutPage />}
              />
              <Route
                path={`${SisfoopsRoutes.dashboard}`}
                element={<DashboardPage />}
              />
              <Route
                path={`${SisfoopsRoutes.notification}`}
                element={<NotificationPage />}
              />
              <Route
                path={`${SisfoopsRoutes.operationPlanning}`}
                element={<OperationPlanningPage />}
              />
              <Route path={`${SisfoopsRoutes.alert}`} element={<AlertPage />} />
              <Route
                path={`${SisfoopsRoutes.operationPreparation}`}
                element={<OperationPreparationPage />}
              />
              <Route
                path={`${SisfoopsRoutes.operationApproval}`}
                element={<OperationStatusPage />}
              />
              <Route
                path={`${SisfoopsRoutes.operationEvaluation}`}
                element={<OperationEvaluationPage />}
              />
              <Route
                path={`${SisfoopsRoutes.delegationTask}`}
                element={<DelegationTaskPage />}
              />
              <Route
                path={`${SisfoopsRoutes.implementationTask}`}
                element={<ImplementTaskPage />}
              />
              <Route
                path={`${SisfoopsRoutes.masterDataOperasi}`}
                element={<MasterDataOperasiPage />}
              />
              <Route
                path={`${SisfoopsRoutes.masterDataPenugasan}`}
                element={<MasterDataPenugasanPage />}
              />
              <Route
                path={`${SisfoopsRoutes.operation_report}`}
                element={<OperationReportPage />}
              />
              <Route
                path={`${SisfoopsRoutes.management_report}`}
                element={<ManagementReportPage />}
              />

              <Route
                path={`${SisfoopsRoutes.backup_restore}/:type`}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <BackupRestorePage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoopsRoutes.management_account}
                element={<AccountViewPage />}
              />

              <Route
                path={SisfoopsRoutes.management_workflow}
                element={<WorkflowPage />}
              />

              <Route
                path={SisfoopsRoutes.account_change_password}
                element={<ChangePasswordPage />}
              />

              <Route
                path={SisfoopsRoutes.account_generate_otp}
                element={<GenerateOtpPage />}
              />
            </Route>
          </Route>

          <Route
            path={SisfoopsRoutes.login}
            element={
              <Suspense fallback={<LoaderPage />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route index element={<LoginPage />} />
          </Route>

          <Route path="*" element={<NoMatch />} />
        </Routes>
      </HashRouter>
      <StyledToastContainerPuspenerbal />
    </>
  );
}

export default App;
