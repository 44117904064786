import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI, BackendDataShape } from "api/config";
import CookieMonster from "api/CookieMonster";
import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";
import {
  APaginationEntity,
  BackendDataPageShape,
  BackendPaginationRequestObject,
} from "types";
import { AMasterData, AMasterDataObj } from "types/endpoints/masterdata";

export const masterOperationStoreAPI = createApi({
  reducerPath: "masterOperationStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/master-operation`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["MasterOperationList"],
  endpoints: (builder) => {
    return {
      restoreMasterOperation: builder.mutation<
        object,
        {
          file: File[];
        }
      >({
        query: (data) => {
          const formData = jsonToFormData(data);
          return {
            url: "/restore",
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ["MasterOperationList"],
      }),
      getMasterOperation: builder.query<
        APaginationEntity<AMasterData[]>,
        BackendPaginationRequestObject<Partial<AMasterDataObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<AMasterData[]>) => {
          const { meta, entities } = response.data;
          const dataWithChip = entities.map((val) => {
            return {
              ...val,
            };
          });
          return { meta, entities: dataWithChip };
        },
        providesTags: ["MasterOperationList"],
      }),
      getMasterOperationById: builder.query<AMasterData, Partial<AMasterData>>({
        query: ({ id, ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = `/${id}` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AMasterData>) =>
          response.data,
        providesTags: ["MasterOperationList"],
      }),
      createMasterOperation: builder.mutation<
        BackendDataShape<AMasterData>,
        Partial<AMasterData>
      >({
        query: (obj) => {
          return {
            url: ``,
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["MasterOperationList"],
      }),
      updateMasterOperation: builder.mutation<
        BackendDataShape<AMasterData>,
        { id: number }
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["MasterOperationList"],
      }),
      deleteMasterOperation: builder.mutation<
        BackendDataShape<string>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["MasterOperationList"],
      }),
    };
  },
});

export const {
  useRestoreMasterOperationMutation,
  useGetMasterOperationQuery,
  useGetMasterOperationByIdQuery,
  useCreateMasterOperationMutation,
  useUpdateMasterOperationMutation,
  useDeleteMasterOperationMutation,
  util: { resetApiState: resetMasterOperationStoreAPI },
} = masterOperationStoreAPI;
